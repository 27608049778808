<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card mb-3">
                    <div class="card-header">Vehicle Select</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-auto">
                                <h6 class="text-center">Default</h6>
                                <vehicle-select id="vehicle-1" title="Title Header" input-value="1" />
                            </div>
                            <div class="col-auto">
                                <h6 class="text-center">Hover</h6>
                                <vehicle-select class="hover" id="vehicle-2" title="Title Header" input-value="2" />
                            </div>
                            <div class="col-auto">
                                <h6 class="text-center">Selected</h6>
                                <vehicle-select id="vehicle-1" title="Title Header" input-value="1" image-url="/assets/images/vehicle-sample.svg" selected />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VehicleSelect from "../components/VehicleSelect";

    export default {
        name: 'Widgets',
        components: {
            VehicleSelect
        },
        data() {
            return {
                selected: null
            }
        }
    }
</script>
