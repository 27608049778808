<template>
    <div :class="['vehicle-select', {'selected': selected || checked}]">
        <div class="vehicle-select-heading">
            <img :src="imageUrl" :alt="title" v-if="imageUrl" class="vehicle-select-img" />
            <div :class="['vehicle-select-upload', {'show': !imageUrl}]">
                <fa icon="image" />
            </div>
            <label class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" :id="id" :name="name" :checked="selected" @change="onChange">
                <span class="custom-control-label"></span>
            </label>
        </div>
        <div class="vehicle-select-body">
            <h6 class="vehicle-select-title">{{ title }}</h6>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VehicleSelect',
        props: {
            id: String,
            name: String,
            title: String,
            selected: Boolean,
            imageUrl: String
        },
        data() {
            return {
                checked: false
            }
        },
        methods: {
            onChange() {
                this.checked = !this.checked;
            }
        }
    }
</script>
